import React from "react"
import Image from "../image"
const AboutUS = () => {
  return (
    <div className="container about-us-container py-5">
      <div className="row align-items-center">
        <div className="col-xs-12 col-md-6 ">
          <div className="">
            <h1 className="about-us-heading-row">About Us</h1>
          </div>
          <div className="">
            <p className="">
              Shop Biz Services is your #1 Source for finding the correct nearby
              contractual workers to complete your home improvement, maintenance
              and repair projects. There is no compelling reason to invest hours
              calling several contract workers from the nearby telephone
              directory or scanning for organizations on the web. Our process
              saves you time and money. To begin, click here and pick the
              service you need to get quotes from local contractors. You'll be
              happy you did!
            </p>
          </div>
        </div>
        <div className="col-xs-12 col-md-6">
          <Image src="contact-us.png"></Image>
        </div>
      </div>
    </div>
  )
}

export default AboutUS
